.footer {
    
}

.footer .top_footer_content {
    background-color: #E4E4E5;
    display: flex;
    flex-direction: row;
    color: rgba(0 0 0 / 85%);
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 8px;
}

.footer .top_footer_content .logo_container {
    width: 250px;
}

.footer .top_footer_content .logo_container > img {
    width: 100%
}

.footer .top_footer_content .left_contnet {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

@media (max-width: 550px) {
    .footer .top_footer_content .left_contnet {
        flex-direction: column;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.164);
    }
    .footer .top_footer_content .logo_container {
        width: 200px;
    }
}



.footer .top_footer_content .links_container {
    flex: 1;
    display: flex;
    gap: 80px;
    justify-content: center;
    width: 250px;
}

.footer .top_footer_content .links_container .left_links ,
.footer .top_footer_content .links_container .right_links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer .top_footer_content .links_container a {
    color: black;
    text-decoration: none;
    position: relative;
}

.footer .top_footer_content .links_container a .link {
    width: fit-content;
    font-weight: 600;
    font-size: 110%;
}

.footer .top_footer_content .links_container a .link::after {
    content: '>';
    margin-left: 3px;
    transition: all .15s ease;
    position: absolute
    /* position: absolute;
    right: -5px;
    width: 10px;
    height: 10px; */
}

.footer .top_footer_content .links_container a .link:hover {
    text-decoration: underline;
}

.footer .top_footer_content .links_container a .link:hover::after {
    margin-left: 7px;
    font-weight: 700;
    font-size: 18px;
}

/* Right content of the top content */
.footer .top_footer_content .right_contnet {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 15px;
    text-align: center;
    padding: 14px 0px;
}

.footer .top_footer_content .right_contnet .subscribe_form {

    display: flex;
    align-items: center;
    justify-self: center;
}

.footer .top_footer_content .right_contnet .subscribe_form input {
    padding: 3px 8px;
    height: 40px;
    width: 300px;
    min-width: 200px;
    outline: none;
    border: none;
}


@media (max-width: 490px){
    .footer .top_footer_content .right_contnet .subscribe_form input {
        height: 40px;
        width: auto;
        min-width: 220px;
    }
    .footer .top_footer_content .right_contnet .subscribe_form button {
        height: 40px;
        width: 100px;
    }
}

@media (max-width: 380px){
    .footer .top_footer_content .right_contnet .subscribe_form input {
        height: 40px;
        width: 180px;
        min-width: 0px;
    }
    .footer .top_footer_content .right_contnet .subscribe_form button {
        height: 40px;
        width: fit-content !important;
        padding: 0px 3px;
    }
}

.footer .top_footer_content .right_contnet .subscribe_form button {
    background: #1A2328;
    color: white;
    height: 40px;
    width: 100px;
    outline: none;
    border: none;
    transition: all .15s linear;
}

.footer .top_footer_content .right_contnet .subscribe_form button:hover {
    background: #1A2328ee;
}

.footer .top_footer_content .right_contnet .follow_links_container {
    display: flex;
    gap: 15px;
}

.footer .top_footer_content .right_contnet .follow_links_container  a {
    width: 40px;
    height: 40px;
    transition: all .1s linear;
}
.footer .top_footer_content .right_contnet .follow_links_container  a:hover {
    transform: scale(1.08);
}
.footer .top_footer_content .right_contnet .follow_links_container a img {
    width: 100%;
    height: 100%;
}



.footer .bottom_footer_content {
    color: black;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;
    /* flex-direction: column; */
    gap: 7px;
    align-items: center;
    /* justify-content: center; */
    background-color: #E1E1E2;
}

.footer .bottom_footer_content  h5 img {
    width: 50px;
    margin: 0px 15px;
}

.footer .bottom_footer_content a {
    text-decoration: none;
    color: black;
}