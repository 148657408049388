
.layout_itself {
    position: relative;
    min-height: 50vh;
}

.layout_childrens_container {
    min-height: 50vh;
    /* padding: 20px; */
    position: relative;
    /* z-index: -100; */
}

.layout_childrens {
    /* position: absolute */
}

.topleft_image_container{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    /* max-height: 400px; */
    z-index: -100;
    user-select: none;
    height: 600px;
}

.topleft_image_container::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0 0 0 / 60%);
}

 .topleft_layout_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.layout_childrens_container .rectangle_layout_bottom_image {
    position: absolute;
    bottom: 20%;
    left: 5%;
    width: 50%;
    /* max-height: 400px; */
    z-index: -100;
    user-select: none;
}

.layout_childrens_container .rectangle_layout_image{
    position: absolute;
    right: 8%;
    top: 35%;
    width: 40%;
    /* max-height: 400px; */
    z-index: -101;
    max-width: 400px;
    user-select: none;
}

.layout_childrens .childrens_themseleves {
    padding: 15px 20px;
    min-height: 60vh;
    /* margin-left: 15px; */
}
.layout_childrens .childrens_themseleves:lang(ar){
    /* margin-right: 15px; */
}
.layout_childrens .childrens_themseleves:lang(en){
    /* margin-right: 15px; */
}

@media (max-width: 768px){
    .layout_childrens .childrens_themseleves {
        padding: 10px 20px;
        /* margin-left: 15px; */
    }
}