
.sidebar {
    width: 100%;
    height: 100%;
    background-color: rgba(0 0 0 , 15%);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 1000;
    transition: all .15s ease-in-out;
}

.sidebar:lang(en) {
    right: auto;
    left: 0px;
}

.sidebar .sidebar_content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}

.sidebar .sidebar_content_fillside {
    width: 300px;
    /* background: #111820; */
    background: white;
    color: black;
    /* height: 100%; */
    /* border: 1px solid black; */
    /* border-top: 1px solid rgba(255, 255, 255, 0.651); */
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between
    /* height: 90%; */

}

.sidebar .sidebar_content_fillside .closeIcon {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    width: 100%;
}

.display-none {
    visibility: hidden;
}

.sidebar .sidebar_content_emptyside {
    flex: 1;
}

.sidebar .sidebar_content_fillside .sidebar_content_routes{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.sidebar .sidebar_content_fillside .sidebar_content_routes .sidebar_link {
    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .sidebar_content_fillside .sidebar_content_routes .sidebar_link:hover {
    background-color: #caa746;
}

.sidebar .sidebar_content_fillside a{
    color: black ;    
    text-decoration: none;
    font-weight: 700;
    position: relative;
    transition: all .11s ease-in;
}

.sidebar .sidebar_content_fillside a.active {
    background-color: #ce8619;
}

.show_sidebar {
    backdrop-filter: blur(2px);
    right: 0px;

}

.show_sidebar:lang(en) {
    right: auto;
    left: 0;
}

.hide_sidebar {
    backdrop-filter: blur(0px);
    right: -100%;
}

.hide_sidebar:lang(en){
    left: -100%;
    right: auto;
}

@media (max-width: 450px){
    .sidebar {
        /* top: 90px; */
    }
}