/* @import url('https://fonts.googleapis.com/css2?family=Abel&family=Archivo:wght@100&family=Lexend+Deca:wght@500&display=swap'); */
/* @import url('../src/assets/static/Cairo-Black'); */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@100;200;300;400;500;600;700;800;900&display=swap");
body{
  -webkit-font-family: "Cairo", sans-serif !important;
  -o-font-family: "Cairo", sans-serif !important;
  font-family: "Cairo", sans-serif !important;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    /* font-family:  'Lexend Deca', sans-serif; */
    /* font-family: Cairo; */
    font-weight: 800;
    color: rgba(255, 255, 255, 0.829) !important;
}
@font-face {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  src: local('Cairo'), local('OpenSans-Light'), url(http://themes.googleusercontent.com/static/fonts/opensans/v6/DXI1ORHCpsQm3Vp6mXoaTaRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
}
.App {
    max-width: 1700px;
    margin: 0px auto;
}

html {
    /* width: 100vw; */
    overflow-x: hidden;
}

body {
    /* width:100vw !important; */
    overflow-x: hidden;
}

/* Adding style for the slider */
.rec-arrow-right ,
.rec-arrow-left{
    background-color: #111820 !important;
    color: white !important;

    /*  #A76405*/
}

.rec-arrow-right:disabled ,
.rec-arrow-left:disabled {
    background-color: #464e57 !important;
}

@media (max-width: 600px){
    .rec-arrow-right ,
.rec-arrow-left{
    width: 35px !important;
    height: 35px !important;
    min-width: 0px !important;
    line-height: normal !important;
    font-size: 1.2rem !important;
}
}

.rec-dot  {
    background: #F2DA8C !important;
}

.rec-dot_active {
    box-shadow: 0 0 2px 3px rgb(0 0 0 / 60%) !important;
    background-color: #A76405 !important;
}


.swiper-container {
    height: 20rem;
    width: 100%;
  
    @media screen and (max-width: 32rem) {
      height: 10rem !important;
    }
  }
  
  .header {
    margin-bottom: 2rem;
  }

  .swiper-wrapper {
    justify-content: center;
  }
  
  .swiper-slide {
    max-height: 20rem;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    font-size: 1.5rem;
  }
  
  .buttons-list {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    /* opacity: 0; */
  }
  .home_slider_images{
    text-align: center;
  }
  .home_slider_images .swiper-slide-auto:nth-child(even){
    margin-top: -6%;
  }

  .swiper-slide-auto {
    width: 180px;
    height: 290px;
    /* padding: 0px 90px; */

  

  }
  .swiper-slide-aut.swiper-slide {
    padding: 4px;
    
  }
.swiper-slide-auto.swiper-slide-active {
    /* box-shadow: inset 0 0 0 4px #000; */
    }
  .swiper-slide-auto img {
    display: block;
    width: 180px;
    height: 290px;

  }
  img:not(.homecenterImage) {
    border-radius: 10px !important;
  }

  @media(max-width: 700px) {
    .swiper-slide-auto img , .swiper-slide-auto{
      display: block !important;
      width: 190px !important;
      height: 220px !important;
  
    }
  }

  
  @media(max-width: 400px) {
    .swiper-slide-auto img , .swiper-slide-auto{
      display: block !important;
      width:150px !important;
      height: 205px !important;
  
    }
  }