.navbar_container {
    width: 100%;
    height: 105px;
    /* background-color: #111820; */
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 15px 20px;
    /* box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12); */
}

.navbar_logo_container {
    /* width: 150px; */
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}


.navbar_container .navbar_logo {
    width: 150px;
    padding: 10px;
}

.navbar_container .navbar_links {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}


.navbar_container .navbar_links a{
    color: white ;    
    text-decoration: none;
    font-weight: 700;
    font-size: 21px;
    position: relative;
    transition: all .11s ease-in;
}

.navbar_container .navbar_links a::before{
    content: '';
    position: absolute;
    bottom: -8px;
    right: 0;
    width: 0px;
    height: 2px;
    transition: all .1s ease-in;
    background-color: #D8AB51;
}

.navbar_container .navbar_links a.active{
    color: #D8AB51;
}

.navbar_container .navbar_links a:hover::before {
    width: 100%;
}

.navbar_container .navbar_links a.active::before {
    width: 100%;
}

.navbar_container .localization_icon {
    width: 40px
}

.nav_icons {
    display: flex;
    /* flex-direction: row-reverse; */
    gap: 10px;
}

.nav_icons > svg {
    margin: 0px !important;
}

.nav_langicon {
    cursor: pointer;
}

.nav_menuIcon {
    display: none !important;
    cursor: pointer;
}


@media (max-width: 788px){
    .nav_menuIcon {
        display: block !important;
    }
    .nav_langicon , .nav_menuIcon {
        font-size: 28px !important;
    }
    .navbar_links {
        display: none !important;
    }
    .navbar_container {
        justify-content: space-between;
    }
    .navbar_logo_container .navbar_logo {
        width: 120px;
        padding: 0px;
    }
}

@media (max-width: 450px){
    .navbar_logo_container .navbar_logo{
        width: 90px;
    }
    .navbar_container {
        height: 90px;
    }
}